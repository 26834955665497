<template>
  <div>
    <v-simple-table
      fixed-header
      height="calc(100vh - 205px)"
      class="table-padding-2-no-top"
    >
      <template v-slot:default>
        <thead class="v-data-table-header">
          <tr>
            <th>
              <SelectFilter
                :label="$t('labels.industry_group')"
                :placeholder="$t('labels.industry_group')"
                :options="industryGroups"
                name="id_industry_group"
                sort-name="industry_group_name"
                has-sort
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th>
              <SelectFilter
                :label="$t('labels.industry')"
                :placeholder="$t('labels.industry')"
                :options="industryOptions(filters.id_industry_group)"
                name="id_industry"
                sort-name="industry_name"
                has-sort
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th>
              <InputFilter
                :label="$t('labels.category')"
                :placeholder="$t('labels.category')"
                name="name"
                sort-name="name"
                has-sort
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th style="width: 120px">
              <SelectFilter
                :label="$t('labels.status')"
                :placeholder="$t('labels.status')"
                :options="statusOptions"
                name="status"
                sort-name="status"
                has-sort
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th class="text-center vertical-align-middle">
              <v-btn small color="primary" @click="addCategory">{{
                $t("labels.add")
              }}</v-btn>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in items" :key="item.id" class="text-center">
            <td class="vertical-align-middle">
              <v-autocomplete
                v-model="item.id_industry_group"
                :disabled="!item.editing"
                :items="industryGroups"
                class="c-input-small input-disabled-bold"
                :label="$t('labels.industry_group')"
                outlined
                dense
                hide-details
                single-line
              ></v-autocomplete>
            </td>
            <td class="vertical-align-middle">
              <v-autocomplete
                v-model="item.id_industry"
                :key="`${index}_${item.id_industry_group}`"
                :disabled="!item.editing"
                :items="industryOptions(item.id_industry_group)"
                class="c-input-small input-disabled-bold"
                :label="$t('labels.industry')"
                outlined
                dense
                hide-details
                single-line
              ></v-autocomplete>
            </td>
            <td class="vertical-align-middle">
              <v-text-field
                v-model="item.name"
                :disabled="!item.editing"
                class="c-input-small input-disabled-bold"
                :placeholder="$t('labels.category')"
                outlined
                dense
                hide-details
                single-line
              ></v-text-field>
            </td>
            <td class="text-center vertical-align-middle">
              <div class="d-flex align-center justify-center">
                <v-checkbox
                  v-model="item.status"
                  :disabled="!item.editing"
                  class="mt-0 ml-1"
                  :value="1"
                  hide-details
                ></v-checkbox>
              </div>
            </td>
            <td class="text-center vertical-align-middle">
              <v-btn
                v-if="!item.editing"
                class="mr-1"
                x-small
                color="warning"
                @click="toggleEditing(item, index, true)"
              >
                {{ $t("labels.edit") }}
              </v-btn>
              <template v-else>
                <v-btn
                  class="mb-1"
                  x-small
                  color="success"
                  @click="saveCategory(item)"
                >
                  {{ $t("labels.save") }}
                </v-btn>
                <br />
                <v-btn
                  x-small
                  color="error"
                  @click="toggleEditing(item, index, false)"
                >
                  {{ $t("labels.cancel") }}
                </v-btn>
              </template>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <v-row>
      <v-col cols="12" md="12">
        <v-pagination
          v-model="page"
          :length="totalPage"
          :total-visible="4"
        ></v-pagination>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { httpClient } from "@/libs/http";
import { YES_NO_OPTIONS } from "@/libs/const";
import { debounce } from "lodash/function";

export default {
  name: "ListCategory",
  components: {
    SelectFilter: () => import("@/components/table/SelectFilter"),
    InputFilter: () => import("@/components/table/InputFilter"),
  },
  data: () => ({
    industryGroups: [],
    industries: [],
    statusOptions: [...YES_NO_OPTIONS],
    items: [],
    isLoading: false,
    hasChange: false,
    page: 1,
    totalPage: 1,
    filters: {
      status: 1,
    },
  }),
  watch: {
    page() {
      this.getList();
    },
    filters: {
      handler() {
        this.page = 1;
        this.getList();
      },
      deep: true,
    },
  },
  mounted() {
    this.getIndustries();
    this.getList();
  },
  methods: {
    onFilterChange(filter) {
      this.filters = { ...this.filters, [filter.name]: filter.value };
    },
    onSortChange(sort) {
      this.filters = { ...this.filters, ...sort };
    },
    changeStatus(status) {
      this.filters = { ...this.filters, status: status };
    },
    addCategory() {
      this.items.unshift({
        id_industry_group: null,
        id_industry: null,
        name: null,
        status: 1,
        editing: true,
      });
    },
    toggleEditing(item, index, editing) {
      item.editing = editing;
      if (!item.id) {
        this.items = [...this.items].filter((i, idx) => idx !== index);
      } else {
        this.items[index] = { ...item };
      }
      this.items = [...this.items];
    },

    async saveCategory(item) {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        await httpClient.post("/save-category", item);
        this.isLoading = false;
        this.$vToastify.success(
          item.id
            ? this.$t("messages.update_success")
            : this.$t("messages.create_success")
        );
        this.getList();
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.isLoading = false;
      }
    },

    async getIndustries() {
      httpClient.post("/industry-group-list").then(({ data }) => {
        this.industryGroups = [...data].map((d) => ({
          text: d.name,
          value: d.id,
        }));
      });
      httpClient.post("/industry-list").then(({ data }) => {
        this.industries = [...data];
      });
    },

    industryOptions(id_industry_group) {
      if (!id_industry_group) {
        return [];
      }
      return [...this.industries]
        .filter((i) => i.id_industry_group === id_industry_group)
        .map((d) => ({
          text: d.name,
          value: d.id,
        }));
    },

    getList: debounce(function () {
      httpClient
        .post("/category-list", { ...this.filters, page: this.page })
        .then(({ data }) => {
          this.totalPage = data.totalPage;
          this.sum = { ...data.sum };
          this.items = [...data.rows];
        });
    }, 500),

    async downloadExcel() {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;
      const filename = "danh-muc-hang-hoa.xlsx";

      try {
        await this.downloadExcelFile(
          "/category-list-export",
          { ...this.filters },
          filename
        );
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped></style>
